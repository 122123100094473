import { Col, Row, Container } from 'react-bootstrap'
import './styles.css';
import Tilt from 'react-parallax-tilt';
import { BsWhatsapp, BsFacebook, BsInstagram } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import Form from './Form'

function Footer() {


  return (

    <div className='py-5' style={{ width: '100%', background: '#333333' }}>
      <h3  data-aos="zoom-out-down" className='mb-0 mb-md-5' style={{ textAlign: 'center', color: '#fff' }}>Contatos</h3>
      <Container  className='containerWrapper'>

        <Row  className='wrapperCardContato'>
          <div style={{ width: 'auto', position: 'relative', left: '25px', zIndex: 1 }} >
            <div  data-aos="zoom-out-down" className='wrapperFooter' >

              <Tilt htmlFor="teste-id" gyroscope={false} className='conatanerContact parallax-effect'>
                <a style={{ textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=${5511977974750}&text=${whatsappMessage}`} target='_blank' rel="noopener noreferrer" className='inner-element'>
                  <BsWhatsapp color='#333333' size={50} />
                  <span>Whatsapp</span>
                </a>
              </Tilt>

              <Tilt gyroscope={false} className='conatanerContact parallax-effect'>
                <a style={{ textDecoration: 'none' }} href={`mailto:eder@edercarpetes.com.br`} target='_blank' rel="noopener noreferrer" className='inner-element'>
                  <MdEmail color='#333333' size={50} />
                  <span>E-mail</span>
                </a>
              </Tilt>

              <Tilt gyroscope={false} className='conatanerContact parallax-effect'>
                <a style={{ textDecoration: 'none' }} href={'https://www.instagram.com/edercarpete/'} target='_blank' rel="noopener noreferrer" className='inner-element'>
                  <BsInstagram color='#333333' size={50} />
                  <span className='titleCardContact'>Instagram</span>
                </a>
              </Tilt>

              <Tilt gyroscope={false} className='conatanerContact parallax-effect'>
                <a style={{ textDecoration: 'none' }} href={`https://www.facebook.com/Eder-Carpetes-103701935010145`} target='_blank' rel="noopener noreferrer" className='inner-element'>
                  <BsFacebook color='#333333' size={50} />
                  <span>Facebook</span>
                </a>
              </Tilt>

            </div>
          </div>
        </Row>

        <Col >
          <div className='wrapper-form-contact'>
            <Form />

          </div>
        </Col>

      </Container>
    </div>

  )
}

export default Footer

const whatsappMessage = 'Olá, Eder Carpetes! Através do seu site conheci o seu trabalho e gostaria de mais informações.'


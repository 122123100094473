import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import  'bootstrap/dist/css/bootstrap.min.css' ;

import Pages from '../src/pages/router';

ReactDOM.render(
	<BrowserRouter>
		<Pages />
	</BrowserRouter>,
	document.getElementById('root')
);


import { Col, Row } from 'react-bootstrap'
import './styles.css';

import background_1 from '../../assets/abuphomegift.jpg';
import background_2 from '../../assets/olympia.jpg';
import background_3 from '../../assets/rock-in-rio-2021.jpg';

function WorksPerformed() {


  return (
    <div  className='container worksContainer'>
      <h3 style={{ textAlign: 'center', color: '#fff' }}>Trabalhos realizados</h3>
      {works.map((work, index) => {
        const reverse = index % 2
        return (
        
          <Row  className='py-3' style={{ display:'flex', flexDirection:  reverse ? 'row-reverse' : 'row'}}>
            <Col data-aos={reverse ? 'fade-left' : 'fade-right'} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', maxHeight: 400 }} className='col-12 col-lg-6 mb-5 mb-lg-0'>
              <section className='module-medium parallax-medium ' style={{ backgroundImage: `url(${work.url})`, borderRadius: 20 }}>
              </section>
            </Col>
            <Col data-aos={reverse ? 'fade-right' : 'fade-left'} className='container-text-event'>
              
              <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <h3 style={{color: '#fff'}} className='event-title text-center mb-4'>{work.title}</h3>
              <p  className='text-center  event-sub-title ' style={{ color: '#fff', fontSize: '20px'}}>
                {work.subTitle}
              </p>
              </div>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}

export default WorksPerformed

const works = [
  {
    url: background_1,
    title: 'ABUP 2022',
    subTitle: ' ABUP- Associação Brasileira de Empresas de Utilidades e Presentes é uma associação sem fins lucrativos formada por um grupo de empresas que atuam no segmento CASA. Responsável pela realização da mais importante feira da América Latina, a Home & Gift & Têxtil, promove os lançamentos dos principais players da indústria e de importados, o melhor do design independente brasileiro e apresenta um panorama completo das tendências para o mercado de decoração, mesa posta, utilidades domésticas e linha têxtil.'

  },
  {
    url: background_2,
    title: 'OLYMPIA 2021',
    subTitle: 'O evento OLYMPIA leva entretenimento, gera negócios e reúne as maiores marcas, os grandes compradores e os principais atletas e influenciadores fitness do país.'
  },
  {
    url: background_3,
    title: 'ROCK IN RIO',
    subTitle: 'O Rock in Rio é um festival de música idealizado pelo empresário brasileiro Roberto Medina pela primeira vez em 1985. É reconhecido como um dos maiores festivais musicais do planeta. Foi originalmente organizado no Rio de Janeiro, de onde vem o nome.'
  }
]
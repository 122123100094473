import './styles.css';


function Form() {
  var url = window.location.origin;
  return (
    <form action='https://api.staticforms.xyz/submit' method='POST' className='containerFormContact'>
      <div className='containerTextContact'>
        <span>Entre em contato<br />
          conosco</span>
      </div>
      <input type="hidden" name="accessKey" value='96757bc8-0484-4e1c-a09b-a2739ec79f94' />
      <input type="hidden" name="redirectTo" value={`${url}/successSendEmail`} />


      <input required type="text" name="name" placeholder='Digite seu nome' />
      <input required type="email" name="email" placeholder='Insira um email valido' />
      <textarea required name="message" id="" cols="20" rows="5" placeholder='Digite sua mensagem'></textarea>
      <button disabled className='btn-submit' type='submit'>
        Enviar
      </button>
    </form>

  )
}

export default Form



import 'aos/dist/aos.css';
import './styles.css';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import animationData from '../../assets/animations/success.json'

export default function Home() {
  // const [animationData, setAnimationData] = useState(true)
  const history = useHistory()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className='wrapper-success-email' >
      <div>
        <div className='wrapper-animatin-success'>
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
        <p>Sua mensagem foi enviada</p>
        <p>com sucesso!</p>
      </div>
      <button className='button-back-success' onClick={() => history.push('/home')} >Voltar</button>
    </div>

  );
}



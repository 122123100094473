import React from 'react';

import Home from '../Home';
import SuccessSendEmail from '../SuccessSendEmail';

import {
    Switch,
    Route,
    } from 'react-router-dom';

export default function Routes(){
    return (
        <Switch>
            <Route path='/' component={Home} exact />
            <Route path='/home' component={Home} exact/>
            <Route path='/successSendEmail' component={SuccessSendEmail} exact/>
        </Switch>
    );
};
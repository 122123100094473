import { Col, Row } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react';
import LogoSvg from '../AnimateLogoSVG';

import './styles.css';

function AboutUs() {

  const inputRef = useRef();
  const [positionLogo, setPositionLogo] = useState(0)



  const scrollHandler = () => {
    setPositionLogo(inputRef.current.getBoundingClientRect().y)
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  return (
    <div className='container'>
      <h3 style={{ textAlign: 'center', color: '#333333', marginBottom: 50 }}>Quem somos</h3>
      <Row>
        <Col style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} className='col-12 col-lg-3 mb-5 mb-lg-0'>
          <Col ref={inputRef} style={{ alignItems: 'center', display: 'flex' }} className='col-5 col-lg-12 '>
            {positionLogo < 800 && <LogoSvg width={'100%'} />}
          </Col>
        </Col>
        <Col data-aos="fade-left" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} className='ms-lg-4'>
          <span style={{ textAlign: 'center', color: '#333333', marginBottom: 5, fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>{textAbout.titleMission}</span>

          <span data-aos="fade-left" className='text-center ' style={{ color: '#333333', fontSize: '20px' }}>
            {textAbout.paragraphMission}
          </span>
          <span data-aos="fade-left" style={{ textAlign: 'center', color: '#333333', marginBottom: 5, fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>{textAbout.titleVision}</span>

          <span data-aos="fade-left" className='text-center ' style={{ color: '#333333', fontSize: '20px' }}>
            {textAbout.paragraphVision}
          </span>
          <span data-aos="fade-left" style={{ textAlign: 'center', color: '#333333', marginBottom: 5, fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>{textAbout.titleValues}</span>

          <span data-aos="fade-left" className='text-center ' style={{ color: '#333333', fontSize: '20px' }}>
            {textAbout.paragraphValues}
          </span>
        </Col>
      </Row>
    </div>
  )
}

export default AboutUs


const textAbout = {
  infoBasic: 'Fundada há .... Anos, a empresa Eder Carpetes tem conquistado cada vez mais espaço no mercado, através do atendimento com pontualidade, compromisso e seriedade com os nossos clientes. Oferecemos forrações de carpetes em feiras de exposições, eventos e residências... carpetes com excelente qualidade e um ótimo acabamento.',
  titleVision: 'Nossa visão',
  paragraphVision: 'Visamos colocar no mercado brasileiro, o manuseio correto de nossos matérias, levando qualidade, preços , escolhas de custo e benefícios à todos consumidores.',
  titleMission: 'Nossa missão',
  paragraphMission: 'Trabalhamos de forma transparente, honrando o compromisso com nossos clientes. Proporcionando experiências positivas e bem defenidas. Oferecendo qualidade e compromisso sempre!', 
  titleValues: 'Nossos valores',
  paragraphValues: 'Atendimento aos clientes e consumidores com o ideal  de um compromisso sério, com  qualidade, satisfação, e total  transparência aos nossos clientes, colaboradores, parceiros e públicos a cada trabalho  oferecido!'

}
import './styles.css';
function HeaderMobile() {

  function menuOnClick() {
    document.getElementById("menu-bar").classList.toggle("change");
    // document.getElementById("menu-bg").classList.toggle("change-bg");
    document.getElementById("nav").classList.toggle("change");
    document.getElementById("menu").classList.toggle("menu-change-bg");
  }



  return (
    <>
      <div  id="menu" >
        <div id="menu-bar" onClick={() => menuOnClick()}>
          <div id="bar1" className="bar"></div>
          <div id="bar2" className="bar"></div>
          <div id="bar3" className="bar"></div>
        </div>
        <nav className='nav' id="nav">
          <ul>
          <li><a href='#aboutUs'>Quem somos</a></li>
            <li><a href='#works'>Trabalhos realizados</a></li>
            <li><a href='#contact'>Contatos</a></li>
            <li><a href='#specialty'>Especialidades</a></li>
          </ul>
        </nav>
      </div>

      {/* <div className="menu-bg" id="menu-bg"></div> */}
    </>
  )
}

export default HeaderMobile



import { Col, Row } from 'react-bootstrap'
import './styles.css';
import carpete from '../../assets/esp-carpete.jpeg'
import grama from '../../assets/esp-grama.jpeg'
import Piso from '../../assets/esp-piso.jpeg'

function CardSpecialty() {

  return (
    <Row className='teste3'>
      <h3 className='mb-0 mb-md-5' style={{ textAlign: 'center', color: '#fff' }}>Especialidades</h3>
      {
        
        cardsSpecialty.map(card => {
          return (
            <Col   className='col-12 mt-5 mt-md-0 col-md-6 col-lg-4 container-card' >
              <img data-aos="zoom-in" className='img-card' src={card.url} alt={card.title} />
              <h3  data-aos="fade-up" className='title-card'>{card.title}</h3>
              <p  data-aos="fade-up" className='descripition-card'>{card.description}</p>
            </Col >
          )
        })
      }

    </Row>
  )
}

export default CardSpecialty

const cardsSpecialty = [
  {
    url: carpete,
    title: 'Carpete',
    description: 'O carpete forração é ideal para eventos como feiras de exposição, formaturas, casamentos etc.  Trabalhamos não só com o carpete tradicional do mercado, mas  também com o Loop, somos especialistas em trabalhos com emendas secas (emenda não perceptíveis) e também com emendas sobrepostas.'
  },
  {
    url: grama,
    title: 'Grama sintética',
    description: 'A grama sintética é ideal para quem gosta de natureza, mas não tem muita prática com jardinagem, pois não tem necessidade de tratamento como: irrigação, poda e entre outros. A grama sintética é indicada para decorar quadras, feiras de exposições e áreas de lazer.'
  },
  {
    url: Piso,
    title: 'Pisos',
    description: 'Na categoria de pisos trabalhamos com decorflex, piso bus  e vinílico. Sendo indicados  para cozinhas, banheiros, ambulatórios entre outros.. Ele tem como destaque a modernidade e conforto  que o proporciona. Uma de suas vantagens é a facilidade da limpeza e manutenção.'
  }
]
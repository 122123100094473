import './styles.css';
import HeaderMobile from './HeaderMobile';
import logo from '../../assets/logo.png';
function Header() {

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className='container containerHeader'>
      <span className='d-none d-lg-block'>
        <img style={{ height: 60 }} src={logo} alt="logo" />
      </span>

      <label className='d-lg-none' >
        <HeaderMobile />
      </label>

      <div className='containerButtonHeader d-none d-lg-flex'>
        <div className='wrapperButton'><a href='#works'>Trabalhos realizados</a></div>
        <div className='wrapperButton'><a href='#contact'>Contatos</a></div>
        <div className='wrapperButton'><a href='#aboutUs'>Quem somos</a></div>
        <div className='wrapperButton'> <a href='#specialty'>Especialidades </a></div>
      </div>
    </div>
  )
}

export default Header


import CardSpecialty from '../../components/CardSpecialty'
import AboutUs from '../../components/AboutUs'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import WorksPerformed from '../../components/WorksPerformed'
import Parallax, { Layer } from "react-parallax-scroll";
import { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';

import './styles.css';

export default function Home() {

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, [])
  return (

    <div className='wrapper' >
      <main>

        <Parallax>
          <Layer className="banner banner-2" settings={{ speed: 0.4 }}>
            <Header />
          </Layer>
        </Parallax>

        <section id='specialty' className='module content'>
          <div className='container'>
            <CardSpecialty />
          </div>
        </section>

        <section id='aboutUs' className='module py-5'>
          <AboutUs />
        </section>

        <section id='works' className='module py-5 bg-image-grey'>
          <WorksPerformed />
        </section>

        <div id='contact'>
          <Footer />
        </div>
      </main>

    </div>

  );
}


